import styled from 'styled-components'

export const FilterContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px;
  border: 1px dashed #c9c9cb;
  border-radius: 6px;
  align-self: center;
  width: 100%;
  margin: 16px 0;
  background-color: #ffffff;

  @media (min-width: 768px) {
    width: 100%;
    position: absolute;
    top: 0px;
    align-self: flex-end;
    max-width: 260px;
    padding: 16px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    right: 0;
  }

  @media (min-width: 1024px) and (max-width: 1333px) {
    right: 20%;
  }

  @media (min-width: 1334px) and (max-width: 1919px) {
    right: 25%;
  }

  @media (min-width: 1920px) {
    right: 30%;
  }
`

export const FilterSubtitleContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`
