import { DocumentListCard, Text } from '@yes.technology/react-toolkit'
import styled, { css } from 'styled-components'
import { ImageFormat } from './EventCard.type'

export const Card = styled(DocumentListCard)`
  height: 100%;
  h3 {
    margin-bottom: 8px;
  }
`

export const Container = styled.div`
  display: flex;
  gap: 16px;
  height: 100%;

  * {
    word-break: break-word;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`

export const Subtitle = styled(Text)`
  color: #006bff;
  line-height: 24px;
`

const getStylesByFormat = (format?: ImageFormat) => {
  if (format === 'portrait') {
    return css`
      width: 192px;
    `
  }

  if (format === 'square') {
    return css`
      margin: auto;
      width: 90px;
      height: 90px;

      @media (min-width: 768px) {
        width: 192px;
        height: 192px;
      }
    `
  }

  return css`
    height: 162px;
  `
}

export const ImageWrapper = styled.div<{ $format?: ImageFormat }>`
  img {
    width: 100%;
    object-fit: cover;
  }

  ${({ $format }) => getStylesByFormat($format)}
`

export const TextContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
