import styled from 'styled-components'
import { HierarchyCard, Text } from '@yes.technology/react-toolkit'

export const StyledCard = styled(HierarchyCard)`
  width: 100%;
  height: 100%;

  > div:first-child {
    height: 100%;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 0.5rem;
`

export const Description = styled(Text)`
  margin: 0;
  text-align: justify;
  flex: 1;
`

export const ImageWrapper = styled.div`
  width: 100%;

  > img {
    height: 80px;
    max-width: 100%;
    object-fit: contain;
  }
`
