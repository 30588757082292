import { Container as StyledTabsContainer } from 'component/Tabs/Tabs.styles'
import { ClassifiedListGrid } from 'site/Renderer/components/DataCards/ClassifiedList/ClassifiedList.styles'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;

  @media (min-width: 1008px) {
    background-size: auto;
  }

  @media (min-width: 1540px) {
    margin-top: -14%;
  }

  @media (min-width: 1024px) and (max-width: 1539px) {
    margin-top: -3%;
  }

  @media (min-width: 576px) and (max-width: 768px) {
    & #tabs-container {
      width: 100% !important;
    }
  }
`

export const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 32px 0 16px 0;

  @media (min-width: 768px) {
    ${StyledTabsContainer} button[role='tab'] > span {
      font-size: 16px !important;
    }
  }

  @media (min-width: 1456px) {
    ${StyledTabsContainer} button[role='tab'] > span {
      font-size: 24px !important;
    }
  }

  @media (min-width: 768px) and (max-width: 1007px) {
    justify-content: flex-start;
    margin: 80px 64px 0;
  }

  @media (min-width: 1008px) and (max-width: 1365px) {
    justify-content: flex-start;
    margin: 64px calc(64px + 20%) 0;
  }

  @media (min-width: 1366px) and (max-width: 1455px) {
    justify-content: flex-start;
    margin: 64px calc(64px + 10%) 0;
  }

  @media (min-width: 1456px) and (max-width: 1903px) {
    justify-content: flex-start;
    margin: 64px calc(64px + 10%) 0;
  }

  @media (min-width: 1904px) {
    justify-content: flex-start;
    margin: 64px calc(64px + 10%) 0;
  }

  @media (min-width: 2062px) {
    justify-content: flex-start;
    margin: 64px calc(64px + 6%) 0;
  }
`
export const CardsContainer = styled(ClassifiedListGrid)`
  padding: 0 16px 24px;
  row-gap: 20px;
  width: 100%;
  min-width: 100%;
  background-color: #f7f8fa;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    padding-bottom: 16px;
  }

  @media (min-width: 768px) {
    padding: 20px 64px;
    margin-top: 160px;
  }

  @media (min-width: 2062px) {
    margin-top: 200px;
  }
`

export const CardWrapper = styled.div`
  div > div > div > div:first-child {
    display: none;
  }
`

export const EmptyStatesContainer = styled.div<{ $color: string }>`
  padding: 32px;
  width: 100%;
  background-color: #f7f8fa;

  svg {
    color: ${({ $color }) => $color};
  }

  @media (min-width: 768px) {
    margin-top: 160px;
  }

  @media (min-width: 2062px) {
    margin-top: 200px;
  }
`
