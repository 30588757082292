import { useCallback, useEffect, useRef, useState } from 'react'
import {
  Button,
  Col,
  DotNavigation,
  LinkButton,
  Row,
  SkeletonDots,
  useIsMobile,
  YesObject
} from '@yes.technology/react-toolkit'

import {
  Content,
  DetailsSectionContainer,
  Image,
  Navigation,
  StyledHierarchyCard,
  Summary,
  Title
} from './SummaryDetailsCarouselContainer.styles'
import {
  IndividualLinkButton,
  SummaryDetailsCarouselContainerProps
} from './SummaryDetailsCarouselContainer.types'
import { useIsOpen } from 'shared/hooks'
import DetailsSection from 'component/DetailsSection'
import { generateSummary } from 'shared/utils/string/summary'
import { StyledGrid } from 'shared/style/grid'
import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'
import EmptyStates from 'component/EmptyStates/EmptyStates'
import { useSiteState } from 'siteState/shared'
import { ScrollToViewHandle } from 'component/DetailsSection/DetailsSection.types'
import useStyleMaps from 'site/Renderer/hooks/useStyleMap'
import usePreloadingImages from 'shared/hooks/usePreloadingImages/usePreloadingImages'
import { ProtectedActionButton } from '../ProtectedActionButton'

function isMappedObject(obj: any): boolean {
  return (
    typeof obj === 'object' &&
    !('type' in obj) &&
    Object.keys(obj).some((key) => key.includes('-'))
  )
}

const SummaryDetailsCarouselContainer = ({
  props
}: SummaryDetailsCarouselContainerProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollToDiv, setScrollToDiv] = useState(false)

  const { isOpen, open, close } = useIsOpen(false)

  const detailsRef = useRef<ScrollToViewHandle>(null)

  const styleProps = useStyleMaps(props.styleMap)

  const isMobile = useIsMobile()

  const {
    items,
    button,
    summary,
    dotNavigationColor,
    titlePosition = 'top',
    imageWidthColumns = 6
  } = props

  const currentItem = items?.[selectedIndex] ?? {}

  const individualLinkButton =
    isMappedObject(button) &&
    (button as IndividualLinkButton)?.[currentItem.uuid]

  const documentUUIDs = items
    ?.map((item) => (item?.documento?.[0] as YesObject)?.uuid)
    .filter(Boolean)

  const images = usePreloadingImages({ documentUUIDs })

  const documentUuid = (currentItem?.documento?.[0] as YesObject)?.uuid
  const image = images?.find((image) => image?.[documentUuid])

  const onNavigate = useCallback((index: number) => {
    setSelectedIndex(index)
  }, [])

  const onReadMore = useCallback(() => {
    open()
    setScrollToDiv(true)
  }, [open])

  const isObjectNotEmpty = Object.keys(currentItem).length > 0

  const [allResultsFetched] = useSiteState('allResultsFetched')
  const isLoading = !allResultsFetched

  useEffect(() => {
    if (!detailsRef.current) return
    if (isOpen && scrollToDiv) {
      detailsRef.current.scrollToContent()
      setScrollToDiv(false)
    }
  }, [isOpen, scrollToDiv])

  if (isLoading) {
    return (
      <StyledGrid style={styleProps}>
        <Row style={{ justifyContent: 'center' }}>
          <Col lg={12}>
            <SkeletonDots />
          </Col>
        </Row>
      </StyledGrid>
    )
  }

  if (!isObjectNotEmpty) {
    return (
      <StyledGrid style={styleProps}>
        <Row style={{ justifyContent: 'center' }}>
          <Col lg={12}>
            <EmptyStates state='no-data-to-view' showComplement={false} />
          </Col>
        </Row>
      </StyledGrid>
    )
  }

  const titlePositionInBreakpoint = isMobile ? 'top' : titlePosition

  const isTitleTop = titlePositionInBreakpoint === 'top'
  const isTitleTopRight = titlePositionInBreakpoint === 'top-right'

  return (
    <StyledGrid style={styleProps}>
      <Row style={{ justifyContent: 'center' }}>
        <Col lg={12}>
          <StyledHierarchyCard>
            {currentItem.des && isTitleTop && <Title>{currentItem.des}</Title>}
            <Content>
              {image && image[documentUuid] && (
                <Col md={imageWidthColumns}>
                  <Image>
                    <img src={image[documentUuid]} alt={currentItem.des} />
                  </Image>
                </Col>
              )}
              <Summary>
                {currentItem.des && isTitleTopRight && (
                  <Title position={titlePositionInBreakpoint}>
                    {currentItem.des}
                  </Title>
                )}

                {currentItem.complement &&
                  (summary ? (
                    generateSummary(currentItem.complement, summary?.max_length)
                  ) : (
                    <SafeHtmlContent html={currentItem.complement} />
                  ))}

                {button?.type === 'modal' && (
                  <Button style={button.style} onClick={onReadMore}>
                    {button.label}
                  </Button>
                )}

                {button?.type === 'link' && (
                  <LinkButton
                    href={button.link.url}
                    target={button.link.target ?? '_self'}
                    style={button.style}
                  >
                    {button.label}
                  </LinkButton>
                )}

                {individualLinkButton && (
                  <LinkButton
                    href={individualLinkButton.link.url}
                    target={individualLinkButton.link.target ?? '_self'}
                    style={individualLinkButton.style}
                  >
                    {individualLinkButton.label}
                  </LinkButton>
                )}

                {button?.type === 'protected' && (
                  <ProtectedActionButton
                    authenticatedRedirectUrl={button.authenticated_redirect_url}
                    signup={{
                      redirectUrl: button.signup.redirect_url,
                      buttonLabel: button.signup.button_label
                    }}
                    login={{
                      redirectUrl: button.login.redirect_url,
                      buttonLabel: button.login.button_label
                    }}
                    informationCard={button.information_card}
                  >
                    <Button style={button.style}>{button.label}</Button>
                  </ProtectedActionButton>
                )}
              </Summary>
            </Content>
          </StyledHierarchyCard>

          {items.length > 1 && (
            <Navigation>
              <DotNavigation
                length={items.length}
                onSelect={onNavigate}
                selected={selectedIndex}
                disabled={items.length <= 1}
                color={dotNavigationColor}
              />
            </Navigation>
          )}

          {isOpen && (
            <DetailsSectionContainer>
              <DetailsSection
                isOpen={isOpen}
                onClose={close}
                des={currentItem.des}
                complement={currentItem.complement}
                ref={detailsRef}
              />
            </DetailsSectionContainer>
          )}
        </Col>
      </Row>
    </StyledGrid>
  )
}

export default SummaryDetailsCarouselContainer
