import { useQueries } from '@tanstack/react-query'
import { FilteractionObject, YesObject } from '@yes.technology/react-toolkit'
import { fetchFilteraction } from 'filteraction/FilteractionResolver'
import { MenuType } from 'site/shared/types/toparea.type'
import { fetchYesObjectsWithFilteraction } from 'yesObject/shared/api/fetchYesObjectsWithFilteraction'

const defaultFetchFilteractionResult = async (
  filteractionId: string
): Promise<Pick<YesObject, 'des' | 'complement'>[] | null> => {
  const filteraction: FilteractionObject | undefined = await fetchFilteraction(
    filteractionId,
    false
  )

  if (!filteraction) {
    return null
  }

  const { objects } = await fetchYesObjectsWithFilteraction({
    filteraction,
    orderby: [
      {
        date_creation_row: 'asc' // temporary classification (sort) ;)
      }
    ]
  })

  return objects
}

type useFetchMenusProps = {
  menus: MenuType[]
  fetchFilteractionResult?: typeof defaultFetchFilteractionResult
}

const useFetchMenus = ({
  menus,
  fetchFilteractionResult = defaultFetchFilteractionResult
}: useFetchMenusProps) => {
  const results = useQueries({
    queries: menus.map(({ filteraction }) => ({
      queryKey: ['filteraction-result', filteraction],
      enabled: !!filteraction,
      queryFn: async () => {
        if (!filteraction) return null
        return fetchFilteractionResult(filteraction)
      }
    })),
    combine: (results) =>
      menus.map((menu, menuIndex) => ({
        label: menu.des,
        items: (results[menuIndex].data || []).map((item) => ({
          label: item.des,
          href: item.complement
        }))
      }))
  })

  return results
}

export default useFetchMenus
