import { Classification, FilterQuery } from '@yes.technology/react-toolkit'

import useYesObject from 'yesObject/shared/hooks/useYesObject'

type UseClassificationArgs = {
  classificationUuid?: string
}

export default function useClassification({
  classificationUuid
}: UseClassificationArgs) {
  const { yesObject, isLoading } = useYesObject({
    objectclassUUID: 'UUID_Objectclass_classification',
    objectUUID: classificationUuid || '',
    enabled: !!classificationUuid,
    showLoading: false,
    skipRelations: true,
    skipMetadata: true
  })

  const classificationContent = yesObject?.classification_content

  const classification =
    typeof classificationContent === 'string'
      ? (JSON.parse(classificationContent) as Classification[])
      : undefined

  const traverseClassifications = (classifications: Classification[]) => {
    let uuids: Array<string> = []

    classifications.forEach((item) => {
      uuids.push(item.UUID_OBJECT)

      uuids = uuids.concat(traverseClassifications(item.CHILDREN || []))
    })

    return uuids
  }

  const childrensUuid = classification
    ? traverseClassifications(classification)
    : []

  const childrensQuery: FilterQuery | undefined = childrensUuid.length
    ? {
        $or: childrensUuid.map((uuid) => ({ uuid: { $eq: uuid } }))
      }
    : undefined

  return {
    classification,
    isLoading,
    childrensQuery
  }
}
