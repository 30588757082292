import {
  ContactObjectCard as ToolkitContactObjectCard,
  YesObject
} from '@yes.technology/react-toolkit'

import WhatsappIcon from './temp-whatsapp-icon.svg'
import { useImageDocument } from 'shared/hooks'

const parseArrayToString = (data: { des: string }[]): string =>
  data.map(({ des }) => des).join(', ')

export type ContactObjectCardProps = Pick<YesObject, 'des'> &
  Partial<Pick<YesObject, 'profile_picture' | 'ocupation' | 'phone' | 'email'>>

const ContactObjectCard = ({
  des,
  profile_picture: profilePicture,
  ocupation,
  phone,
  email
}: ContactObjectCardProps) => {
  const { imageUrl } = useImageDocument({
    document: profilePicture || ''
  })

  return (
    <div className='mt-2'>
      <ToolkitContactObjectCard
        avatarUrl={imageUrl || undefined}
        name={des}
        contactRole={ocupation as string}
        phone={parseArrayToString((phone as YesObject[]) ?? [])}
        email={parseArrayToString((email as YesObject[]) ?? [])}
        phoneIcon={phone && WhatsappIcon} // TODO: Waiting to be added at react-toolkit
        style={{ minHeight: 136 }}
      />
    </div>
  )
}

export default ContactObjectCard
