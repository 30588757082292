import { useTranslation } from 'react-i18n-lite'

import { SkeletonDots, Text, YesObject } from '@yes.technology/react-toolkit'

import { useFetchYesObjects } from 'yesObject/shared'

import EventCard from '../EventCard/EventCard'

import { StyledCol } from './Event.styles'
import { ImageFormat } from '../EventCard/EventCard.type'

type EventProps = {
  object: YesObject
}

export const Event = ({ object }: EventProps) => {
  const { language, t } = useTranslation()

  const holder = (object.holder_organization?.[0] as YesObject)?.des
  const creator = (object.organizer_organization?.[0] as YesObject)?.des
  const establishment = (object.establishment?.[0] as YesObject)?.des
  const establishmentUuid = (object.establishment?.[0] as YesObject)?.uuid

  const dateOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }

  const startDate = new Date(object.start_date as string).toLocaleString(
    language,
    dateOptions
  )

  const endDate = new Date(object.end_date as string).toLocaleString(
    language,
    dateOptions
  )

  const city = (object.city?.[0] as YesObject)?.des
  const state = (object.state?.[0] as YesObject)?.sigla

  const { yesObjects: address, isFetching: isLoadingAddress } =
    useFetchYesObjects({
      filterBody: {
        query: {
          $and: [{ 'establishment_address.uuid': { $in: [establishmentUuid] } }]
        },
        objectclass: window.addressObjectclassUuid
      },
      enabled: !!establishmentUuid
    })

  return (
    <StyledCol key={object.uuid} md={6} xl={4} style={{ marginBottom: '16px' }}>
      <EventCard
        des={object.des}
        startDate={`${t('event-card.start-date')}: ${startDate}`}
        endDate={`${t('event-card.end-date')}: ${endDate}`}
        documents={object.document as YesObject[]}
        documentFormat={object.document_format as ImageFormat}
      >
        <>
          {holder && (
            <div>
              <Text variant='content-03' as='div'>
                {`${t('event-card.holder')}: ${holder}`}
              </Text>

              <Text variant='content-03' as='div'>
                {`${t('event-card.creator')}: ${creator}`}
              </Text>
            </div>
          )}

          {establishment && (
            <div>
              <Text variant='content-emphasis-03' as='div'>
                {t('event-card.establishment')}:
              </Text>

              <Text variant='content-03' as='div'>
                {establishment as string} |{' '}
                {isLoadingAddress && <SkeletonDots />}
                {!isLoadingAddress &&
                  address?.[0] &&
                  `
                  ${address?.[0]?.des},
                  ${address?.[0]?.number},
                  ${address?.[0]?.complement ? `${address?.[0]?.complement}, ` : ''}
                  ${address?.[0]?.neighborhood}
                  - ${city}/${state}
                  `}
              </Text>
            </div>
          )}

          {object.complement && (
            <div>
              <Text variant='content-emphasis-03' as='div'>
                {t('event-card.des')}:
              </Text>

              <Text variant='content-03' as='div'>
                {object.complement}
              </Text>
            </div>
          )}

          {object.obs && (
            <div>
              <Text variant='content-emphasis-03' as='div'>
                {t('event-card.obs')}:
              </Text>

              <Text variant='content-03' as='div'>
                {object.obs as string}
              </Text>
            </div>
          )}
        </>
      </EventCard>
    </StyledCol>
  )
}

export default Event
