import {
  DocumentListCard,
  StatusObject,
  SyntheticButton,
  Unifree
} from '@yes.technology/react-toolkit'
import { useTranslation } from 'react-i18n-lite'
import { ImpactClass } from 'shared/types/toolkit'

import { StatusAction } from 'status/shared/situation.type'

export type StatusCardProps = {
  statusUuid: string
  statuses: StatusObject[]
  statusActions: StatusAction[]
  updateStatus: (destinationStatusCode: string) => void
  informational?: boolean
}

export default function StatusCard({
  statusUuid,
  statuses,
  statusActions,
  updateStatus,
  informational = false
}: StatusCardProps) {
  const isCurrentStatus = (status: StatusObject) => status.code === statusUuid

  const statusDescription = statuses.find(isCurrentStatus)?.des || ''

  const shouldShowReset = (statusAction: StatusAction) =>
    !statusAction.allowed_origin_status &&
    statusAction.destination_status[0].code !== statusUuid
  const visibleStatusActions = statusActions.filter(
    (statusAction) =>
      statusAction.allowed_origin_status?.some(isCurrentStatus) ||
      shouldShowReset(statusAction)
  )

  const options = visibleStatusActions.length
    ? [
        {
          children: 'Status Action',
          impactClass: 'statusChange' as ImpactClass
        },
        ...visibleStatusActions.map((statusAction) => ({
          children: statusAction.des,
          onClick: () => {
            updateStatus(statusAction.destination_status[0].code)
          },
          impactClass: 'statusChange' as ImpactClass
        }))
      ]
    : []

  const { t } = useTranslation()

  const showStatusActions = !informational && options.length > 0

  return (
    <div className='col-md-6'>
      <DocumentListCard className='p-2'>
        <div className='row align-items-end g-1'>
          <div
            className={`${showStatusActions ? 'col-12 col-xl-9' : 'col-md-12'}`}
          >
            <Unifree
              disabled
              label='Status'
              value={statusDescription}
              required
              readOnly={informational}
            />
          </div>
          {showStatusActions && (
            <div className='col-12 col-xl-3'>
              <SyntheticButton
                {...{ options }}
                dropdownLabel={t('status-card.show-status-actions')}
                variant='primary'
                heightClass='regular'
              />
            </div>
          )}
        </div>
      </DocumentListCard>
    </div>
  )
}
