import { Icon } from '@yes.technology/react-toolkit'

import { StyledHierarchyCard } from '../Cockpit/Cockpit.styles'
import { DataOrigin, FilterDate } from './AppliedFilters.styles'
import { useTranslation } from 'react-i18n-lite'
import formatDate from 'shared/utils/date'

export type AppliedFiltersProps = {
  referencePeriodStartISO?: string
  referencePeriodEndISO?: string
  referenceDateISO?: string
}

const AppliedFilters = ({
  referenceDateISO,
  referencePeriodEndISO,
  referencePeriodStartISO
}: AppliedFiltersProps) => {
  const { t } = useTranslation()
  const currentYear = new Date().getFullYear()
  const firstDayOfYear = new Date(currentYear, 0, 1).toISOString()
  const lastDayOfYear = new Date(currentYear, 11, 31).toISOString()
  const today = new Date().toISOString()

  return (
    <StyledHierarchyCard>
      <DataOrigin>
        <h1>{t('cockpit.filter.data-origin-title')}</h1>
        <FilterDate>
          <div>
            <Icon iconName='FiltersFill' icSize='extraSmall' />
            {t('cockpit.filter.reference-period')} ≥{' '}
            {formatDate(referencePeriodStartISO || firstDayOfYear, {
              dateStyle: 'short'
            })}
          </div>
          <div>
            {t('cockpit.filter.reference-period')} ≤{' '}
            {formatDate(referencePeriodEndISO || lastDayOfYear, {
              dateStyle: 'short'
            })}
          </div>
          <div>
            {t('cockpit.filter.reference-date')} ={' '}
            {formatDate(referenceDateISO || today, {
              dateStyle: 'short'
            })}
          </div>
        </FilterDate>
      </DataOrigin>
    </StyledHierarchyCard>
  )
}

export default AppliedFilters
